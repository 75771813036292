import { Component, Input, OnInit } from '@angular/core';
import { KatexParagraphComponent } from 'ng-katex';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit
{
  latex = '';
  @Input() dataPath: string;

  constructor(private http: HttpClient) { }

  ngOnInit()
  {
    this.getQuestionData();
  }

  getQuestionData(): void
  {
    this.http.get(`assets/questions/${this.dataPath}.tex`, { responseType: 'text' }).subscribe(data =>
    {
      this.latex = data;
    });
  }
}
